<template>
  <div
    class="class-statis"
    :style="[{ '--data-column-width': dataColumnWidth + 'px' }]"
  >
    <!-- start 条件过滤区域 -->
    <div class="df-row">
      <div class="df-col" v-if="authType == 'H'">
        <!-- 门店选择器 -->
        <el-select
          v-model="submitData.StoresGuids"
          multiple
          collapse-tags
          clearable
          size="mini"
          placeholder="请选择门店"
          @change="onChangeStores"
        >
          <el-option
            v-for="item in storesList"
            :key="item.StoresGuid"
            :label="item.StoresName"
            :value="item.StoresGuid"
          >
          </el-option>
        </el-select>
      </div>
      <div class="df-col">
        <!-- 职位选择器 -->
        <el-select v-model="submitData.DutyNo" size="mini" placeholder="不限">
          <el-option
            v-for="item in dutyList"
            :key="item.DutyNo"
            :label="item.DutyName"
            :value="item.DutyNo"
          >
          </el-option>
        </el-select>
      </div>
      <div class="df-col">
        <!-- 日期选择器 -->
        <el-date-picker
          v-model="selectDateValue"
          size="mini"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>
      <div class="df-col">
        <!-- 累计业绩 -->
        <el-checkbox
          v-model="submitData.CumulativePerfor"
          :true-label="1"
          :false-label="0"
          >累计业绩</el-checkbox
        >
      </div>
      <div class="df-col">
        <!-- 赠送项目 -->
        <el-checkbox
          v-model="submitData.ShowDonaItem"
          :true-label="1"
          :false-label="0"
          >赠送项目</el-checkbox
        >
      </div>
      <div class="df-col">
        <!-- 查询按钮 -->
        <el-button type="primary" size="mini" @click="onSearch">
          查询
        </el-button>
      </div>
      <div class="df-col">
        <!-- 自定义表格按钮 -->
        <el-button type="primary" size="mini" @click="onCustomDialog"
          >自定义表格</el-button
        >
      </div>
      <div class="df-col">
        <!-- 导出表格按钮 -->
        <el-button
          type="primary"
          size="mini"
          :disabled="excelDisabled"
          @click="onExportExcel"
          >导出表格</el-button
        >
      </div>
    </div>
    <!-- end 条件过滤区域 -->

    <div id="table-loading" v-loading="loading">
      <!-- start 员工项目分类汇总表 -->
      <el-table
        v-show="isShowTable"
        ref="table"
        id="exprot-table"
        border
        stripe
        :data="dataList"
        :summary-method="onTotal"
        :max-height="tableMaxHeight"
      >
        <!-- 门店这一列是固定的 -->
        <el-table-column
          v-if="item.Code == 'Employee'"
          v-for="(item, index1) in topList"
          :key="`A${index1}`"
          :label="item.Name"
          align="center"
          :fixed="isFixedRow"
          :width="columnMaxWidth"
        >
          <el-table-column
            v-if="item.Code == item4.TopCode"
            v-for="(item4, index4) in headList"
            :key="`B${index4}`"
            :label="item4.Name"
            :prop="item4.DataKey"
            show-overflow-tooltip
            :width="tableColumnWidth(item4)"
          >
            <template slot-scope="scope">
              <div>{{ scope.row[item4.DataKey] }}</div>
            </template>
          </el-table-column>
        </el-table-column>

        <!-- 合并列表头（一级表头），由于表格需要自定义，只能依靠循环渲染列的数据，而列的循环顺序则依赖后端接口返回的data顺序，
      所以如果需要修改渲染出来的顺序就要让后端改 -->
        <el-table-column
          v-if="item.Code != 'Employee'"
          v-for="(item, index) in topList"
          :key="index"
          :label="item.Name"
          align="center"
        >
          <!-- 子表头（二级表头） -->
          <el-table-column
            v-if="item.Code == item2.TopCode"
            v-for="(item2, index2) in headList"
            :key="index2"
            :label="item2.Name"
            :prop="item2.DataKey"
            show-overflow-tooltip
            :width="dataColumnWidth || ''"
          >
            <template slot-scope="scope">
              <div ref="tb-col">{{ scope.row[item2.DataKey] }}</div>
              <div>
                {{
                  scope.row[item2.DataKeyPlus] != undefined
                    ? "(" + scope.row[item2.DataKeyPlus] + ")"
                    : ""
                }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <!-- end 员工项目分类汇总表 -->

      <table
        ref="summary"
        v-if="dataList.length > 0"
        class="summary-box"
        cellpadding="0"
        cellspacing="0"
        border="0"
      >
        <tbody>
          <tr>
            <!-- 不需要合计的动态空值列 -->
            <td class="summary-item-fixed">
              <div
                v-if="item.TopCode == 'Employee'"
                v-for="(item, index) in headList"
                :key="index"
                :style="'position: ' + isFixedRow ? 'unset' : 'sticky'"
                :class="item.DataKey == 'StoresName' ? 'stores-name' : ''"
              >
                {{ index == 0 ? "合计：" : "" }}
              </div>
            </td>

            <td
              v-if="item.TopCode != 'Employee'"
              v-for="(item, index) in headList"
              :key="index"
              class="summary-item"
            >
              <div>
                <div>{{ total[item.DataKey] }}</div>
                <div>
                  {{
                    total[item.DataKeyPlus] != undefined
                      ? "(" + total[item.DataKeyPlus] + ")"
                      : ""
                  }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- start 自定义表格对话框 -->
    <CustomDialog
      ref="customDialog"
      @onConfirm="onSubmitClassStatis"
    ></CustomDialog>
    <!-- end 自定义表格对话框 -->
  </div>
</template>

<script>
import report from "@/api/report.js";
import CustomDialog from "@/views/report/components/custom-dialog.vue";

export default {
  components: { CustomDialog },

  data() {
    return {
      submitData: {
        // 提交数据
        StoresGuids: [], // 门店id数组
        DutyNo: "", // 职位编号
        BeginDate: "", // 开始日期
        EndDate: "", // 结束日期
        CumulativePerfor: 1, // 累计业绩
        ShowDonaItem: 1, // 赠送项目
      },
      total: {},
      headData: {}, // 自定义对话框表头源数据（尽可能不要污染）
      heads: [], // 自定义对话框的表头列表
      topList: [], // 表格的合并表头（一级表头）
      headList: [], // 表格的表头（二级表头）
      selectDateValue: [], // 日期选择器数据
      dutyList: [], // 职位列表
      storesList: [], // 门店列表
      dataList: [], // 表格内容数据列表
      tableMaxHeight: "", // 表格最大高度
      columnMaxWidth: "",
      dataColumnWidth: 0,
      authType: "", // 当前账号等级 H 总店 M 各门店
      isShowTable: false,
      loading: false, // 表格加载
      isFixedRow: true, // 固定列禁用
      excelDisabled: false, // 导出按钮禁用
    };
  },

  created() {
    this.getResize();
    this.isShowTable = false;
  },

  async mounted() {
    let accountInfo = this.storage.getStorage("accountInfo");
    this.authType = accountInfo.authType;
    this.storesList = "";
    if (accountInfo.manageStoresList) {
      this.storesList = [
        { StoresGuid: "", StoresName: "全部" },
        ...accountInfo.manageStoresList,
      ];
    }

    window.addEventListener("scroll", this.scrollMove, true);
    window.addEventListener("resize", this.getResize);

    await this.initDateState();
    await this.initEmplBasicClassStatis();
    await this.fetchEmplBasicClassStatis();
  },

  destroyed() {
    window.removeEventListener("scroll", this.scrollMove, true);
    window.removeEventListener("resize", this.getResize);
  },

  computed: {
    // 判断表格内容返回class
    tableColumnWidth() {
      return function(event) {
        if (event.TopCode == "Employee" && event.DataKey == "StoresName") {
          return "210";
        } else if (event.TopCode == "Employee") {
          return "120";
        }
      };
    },
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 68 + 60);
    },

    // 滚动条联动
    scrollMove(event) {
      let that = this;
      let table = that.$refs.table.bodyWrapper; // table表格dom
      let summary = that.$refs.summary; // 合计行dom
      if (event.target.className == "summary-box") {
        table.scrollLeft = summary.scrollLeft;
      } else {
        summary.scrollLeft = table.scrollLeft;
      }
    },

    // 计算表格浮动列的总宽度
    tableColumnMaxWidth(headList) {
      let sum = 0;
      for (let i = 0; i < headList.length; i++) {
        if (headList[i].TopCode != "Employee") {
          break;
        }
        if (headList[i].DataKey == "StoresName") {
          sum += 210;
        } else {
          sum += 120;
        }
      }
      this.$nextTick(() => {
        // sum += 200
        this.columnMaxWidth = sum.toString();
        // console.log(sum.toString());
        // return sum.toString();
      });
    },

    // 初始化下拉框用到的数据
    async initEmplBasicClassStatis() {
      try {
        let { data } = await report.initEmplBasicClassStatis();
        this.dutyList = data.dutyList;
      } catch (e) {}
    },

    // 获取表头设置
    async fetchEmplBasicClassStatis(type = 0) {
      if (!type) {
        try {
          let { data } = await report.fetchEmplBasicClassStatis();
          let { Heads, HeadTops } = data;
          this.headData = data;
          this.heads = Heads;
        } catch (e) {}
      }
    },

    // 初始化表格数据信息
    async queryEmplBasicClassStatis() {
      let dataColumnWidth = 0;
      try {
        let { data } = await report.queryEmplBasicClassStatis(this.submitData);
        let { DataList, HeadList, TopList, Total } = data;
        await this.tableColumnMaxWidth(HeadList);
        this.dataList = await DataList;
        this.headList = await HeadList;
        this.topList = await TopList;
        this.total = Total;

        this.$nextTick(() => {
          setTimeout(() => {
            this.dataColumnWidth = this.$refs["tb-col"][0].offsetWidth + 28;
            console.log(this.dataColumnWidth);
          }, 100);
        });
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },

    // 初始化日期状态
    async initDateState() {
      let { beginDay, endDay } = await this.$store.dispatch("onGetDateState");
      this.selectDateValue = [beginDay, endDay];
      this.submitData.BeginDate = beginDay;
      this.submitData.EndDate = endDay;
    },

    // 日期选择器改变时间
    onChangeDate(event) {
      this.$store.dispatch("onChangeDateState", { dayTime: event });
      this.initDateState();
    },

    // 改变门店选中事件
    onChangeStores(event) {
      let res = event.filter((item) => item == "");
      if (res.length > 0) {
        this.submitData.StoresGuids = [];
        this.storesList.forEach((e) => {
          this.submitData.StoresGuids.push(e.StoresGuid);
        });
        this.submitData.StoresGuids.shift();
      }
    },

    // 查询按钮点击事件
    async onSearch() {
      this.isShowTable = true;
      if (this.authType == "H" && this.submitData.StoresGuids.length == 0) {
        return this.$message.error("请选择门店");
      }
      this.loading = await true;
      await this.$nextTick(async () => {
        await this.queryEmplBasicClassStatis();
      });
    },

    // 打开自定义表格对话框
    onCustomDialog() {
      this.$refs.customDialog.onShowDialog(this.headData);
    },

    // 提交表格自定义数据
    async onSubmitClassStatis(event) {
      let { Heads } = event;
      this.heads = [];
      this.dataList = [];
      this.topList = [];
      this.headList = [];
      this.total = [];
      this.heads = Heads;
      this.saveEmplBasicClassStatis(Heads);
    },

    // 保存自定义表头设置
    async saveEmplBasicClassStatis() {
      try {
        // 保存自定义列多选的表头数据到数据库，让每次刷新获取表格数据的接口返回的是你保存的自定义列对应的表格数据
        let { errcode } = await report.saveEmplBasicClassStatis({
          Heads: this.heads,
        });
        if (errcode === 0) {
          this.loading = await true;
          this.$message.success("表格设置保存成功");
          this.fetchEmplBasicClassStatis();
          this.queryEmplBasicClassStatis();
        }
      } catch (err) {
        console.log(err);
      }
    },

    // 自定义合计
    onTotal({ columns, data }) {
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (index < 4) {
          return;
        } else {
          sums[index] = this.total[column.property];
        }
      });
      return sums;
    },

    // 导出表格按钮点击事件
    onExportExcel() {
      this.excelDisabled = true;
      this.isFixedRow = false;
      this.$nextTick(() => {
        try {
          this.util.onExportExcel("#table-loading", "员工项目分类统计表");
        } finally {
          this.excelDisabled = false;
          this.isFixedRow = true;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.class-statis {
  .df-row {
    margin-top: 20px;

    .el-checkbox {
      margin-top: 5px;
    }
  }

  .summary-box {
    width: 100%;
    height: 60px;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    overflow-x: hidden;

    > div {
      height: 100%;
      border-right: 1px solid #eee;
    }

    div {
      box-sizing: border-box;
      background-color: white;
    }

    .summary-item-fixed {
      position: sticky;
      left: 0;
      top: 0;
      width: 570px;
      height: 60px;
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
      border: unset;
      border-left: 1px solid #bbb;
      border-bottom: 1px solid #bbb;

      div {
        .flex-col;
        align-items: unset;
        justify-content: center;
        width: 120px;
        height: 100%;
        padding: 10px;
        border-right: 1px solid #bbb;
      }

      .stores-name {
        width: 210px;
      }
    }

    .summary-item {
      // width: 80px !important;
      border-bottom: 1px solid #bbb;

      > div {
        .flex-col;
        align-items: unset;
        justify-content: center;
        // width: 80px;
        width: calc(var(--data-column-width));
        // height: 100%;
        height: calc(60px - 2px);
        padding: 10px 20px;
        border-right: 1px solid #bbb;
        box-sizing: border-box;
      }
    }
  }
}

@media (max-width: 1280px) {
  .class-statis {
    .df-row {
      .flex-row;
      align-items: unset;
      flex-wrap: wrap;
      margin-block: 20px 10px;

      .df-col {
        margin-block: 0 10px;
        .el-date-editor {
          width: 240px;
        }
      }
    }
  }
}
</style>
